import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Button,
  Box,
  Card,
  Typography,
  Stack,
  Modal,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import CancelIcon from "@mui/icons-material/Cancel";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { styled } from "@mui/material/styles";
import Label from "../../../components/label";
import { useEffect, useState } from "react";
import NOT_INTERESTED from "../../../graphql/mutations/notInterestedInCampaign";
import ACCEPT_OFFER from "../../../graphql/mutations/influencerAcceptPaidContract";
import RESPOND_TO_PAID_INVITE from "../../../graphql/mutations/respondToPaidInvite";
import SHOW_PAID_INTEREST from "../../../graphql/mutations/interestedInPaidCampaign";
import REJECT_CAMPAIGN from "../../../graphql/mutations/rejectCampaign";
import ContractPopup from "../../../components/ContractPopup";

// ----------------------------------------------------------------------

const StyledProductImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------
const campaignMedia = {
  All: "TikTok & Instagram",
  TikTok: "TikTok",
  Instagram: "Instagram",
};

NewPaidCampaignCard.propTypes = {
  product: PropTypes.object,
};

const userAgent =
  typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );
function debounce(func, delay = 250) {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
export default function NewPaidCampaignCard({ campaign, activeFilter }) {
  const {
    id,
    name,
    coverPic,
    campaignType,
    media,
    contract,
    campaignContracts,
    description,
    includesStories,
    includesReels,
    includesVideos,
    includesPictures,
    status,
  } = campaign;

  const userId = localStorage.getItem("currentUser");

  const filteredContract = campaignContracts.find(
    (obj) => obj.userId == userId
  );

  const debouncedUserInput = debounce((e) => setDescription(e));
  const [myQuote, setMyQuote] = useState(0);
  const [agreed, setAgreed] = useState(false);
  const [myDescription, setDescription] = useState("");
  const [openInterestModal, setOpenInterestModal] = useState(false);
  const [brandQuote, setBrandQuote] = useState(0);
  const [influencerQuote, setInfluencerQuote] = useState(0);
  const [defaultContract, setDefaultContract] = useState("");
  const [extendedContract, setExtendedContract] = useState("");

  const [showInterest] = useMutation(SHOW_PAID_INTEREST, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: id,
      influencerQuote: parseInt(myQuote),
      description: myDescription,
    },
    onCompleted: () => {
      setMyQuote(0);
      setDescription("");
      setOpenInterestModal(false);
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });
  const [respondToInvite] = useMutation(RESPOND_TO_PAID_INVITE, {
    variables: {
      influencerQuote: parseInt(myQuote),
      influencerId: localStorage.getItem("currentUser"),
      campaignId: id,
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
    onCompleted: () => {
      setOpenInterestModal(false);
    },
  });
  const [notInterested] = useMutation(NOT_INTERESTED, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: id,
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });
  const [rejectCampaign] = useMutation(REJECT_CAMPAIGN, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: id,
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });
  const [acceptOffer] = useMutation(ACCEPT_OFFER, {
    variables: {
      campaignId: id,
      influencerId: localStorage.getItem("currentUser"),
      finalFee: brandQuote,
    },
    onCompleted: () => {
      setMyQuote(0);
      setDescription("");
      setOpenInterestModal(false);
      setAgreed(false);
    },
    refetchQueries: [
      "getCandidateCampaignsForInfluencer",
      "getInterestedCampaignsForInfluencer",
      "getInvitedCampaignsForInfluencer",
      "getCampaignsForInfluencer",
    ],
  });
  const loadExtendedContract = async (contractLink) => {
    await fetch(contractLink).then((r) => {
      r.text().then((d) => {
        setExtendedContract(d);
      });
    });
  };
  const loadContract = async () => {
    await fetch(
      "https://authentic-pps.s3.eu-west-2.amazonaws.com/authentic_default_contract.txt"
    ).then((r) => {
      r.text().then((d) => {
        setDefaultContract(d);
      });
    });
  };
  const handleInterest = () => {
    loadContract();
    contract && loadExtendedContract(contract);
    setOpenInterestModal(true);
  };

  useEffect(() => {
    loadContract();
    if (
      (activeFilter === "Invited" || activeFilter === "Interested") &&
      brandQuote === 0 &&
      influencerQuote === 0
    ) {
      const currentUser = localStorage.getItem("currentUser");
      campaignContracts.forEach((contract) => {
        if (contract.userId.toString() === currentUser.toString()) {
          setBrandQuote(contract.brandQuote || "");
          setInfluencerQuote(contract.influencerQuote || "");
        }
      });
    }
  }, [campaignContracts, activeFilter, brandQuote, influencerQuote]);

  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <Label
          variant="filled"
          color={(campaignType === "Paid" && "success") || "primary"}
          sx={{
            zIndex: 9,
            top: 16,
            right: 16,
            position: "absolute",
            textTransform: "uppercase",
            color: "#FFF",
            padding: 2,
          }}
        >
          <CurrencyPoundIcon />
        </Label>
        <Typography
          variant="subtitle1"
          sx={{
            zIndex: 9,
            bottom: 0,
            left: 0,
            position: "absolute",
            textTransform: "uppercase",
            color: "#FFF",
            padding: 1,
            background: "rgba(0, 0, 0, 0.7)",
            width: "100%",
          }}
        >
          {name}
        </Typography>
        <StyledProductImg alt={name} src={coverPic} />
      </Box>

      <Stack spacing={2} sx={{ p: 1 }}>
        <Typography variant="subtitle1" noWrap align="center">
          {campaignMedia[media]} Campaign
        </Typography>
        {status === "Cancelled" && (
          <Typography variant="caption" noWrap align="center" color="error">
            Cancelled
          </Typography>
        )}

        {status !== "In_Progress" && (
          <Typography variant="caption" noWrap align="center" color="error">
            Not Yet Started
          </Typography>
        )}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {activeFilter === "Interested" && (
            <Grid item xs container direction="column">
              {influencerQuote && (
                <>
                  <Typography variant="subtitle1" noWrap align="center" mb={1}>
                    My Offer: &nbsp;£ {influencerQuote}
                  </Typography>
                  <Grid item xs container justifyContent="space-between">
                    {brandQuote && (
                      <>
                        <Typography
                          variant="subtitle1"
                          noWrap
                          align="center"
                          sx={{ width: "100%" }}
                          mb={1}
                        >
                          Brand Offer: £{brandQuote}
                        </Typography>

                        <Button
                          variant="outlined"
                          onClick={() => acceptOffer()}
                          size="small"
                        >
                          Accept
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => rejectCampaign()}
                          size="small"
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          )}
          {activeFilter === "New" && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                onClick={() => handleInterest()}
                sx={{
                  "&:hover": {
                    color: "#FFA3C7",
                  },
                }}
              >
                <Typography variant="caption">Interested &nbsp;</Typography>
                <FavoriteIcon fontSize="small" color="primary" />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                onClick={() => notInterested()}
                sx={{
                  "&:hover": {
                    color: "#FF4842",
                  },
                }}
              >
                <Typography variant="caption">Not Interested &nbsp;</Typography>
                <CancelIcon fontSize="small" color="error" />
              </Stack>
            </>
          )}
          {activeFilter === "Invited" && (
            <>
              {
                <Grid item xs container direction="column">
                  {
                    <Grid item xs container justifyContent="space-between">
                      {influencerQuote && (
                        <Typography
                          variant="subtitle1"
                          noWrap
                          align="center"
                          sx={{ width: "100%" }}
                          mb={1}
                        >
                          My Offer: £ {influencerQuote}
                        </Typography>
                      )}
                      {brandQuote && (
                        <Typography
                          variant="subtitle1"
                          noWrap
                          align="center"
                          mb={1}
                          sx={{ width: "100%" }}
                        >
                          Brand Offer: &nbsp;£{brandQuote}
                        </Typography>
                      )}
                      {!brandQuote && !influencerQuote && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => handleInterest()}
                          sx={{
                            "&:hover": {
                              color: "#FFA3C7",
                            },
                          }}
                        >
                          <Typography variant="caption">
                            Respond &nbsp;
                          </Typography>
                          <FavoriteIcon fontSize="small" color="primary" />
                        </Stack>
                      )}
                      {brandQuote && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          onClick={() => acceptOffer()}
                          sx={{
                            "&:hover": {
                              color: "#FFA3C7",
                            },
                          }}
                        >
                          <Typography variant="caption">
                            Accept &nbsp;
                          </Typography>
                          <FavoriteIcon fontSize="small" color="primary" />
                        </Stack>
                      )}
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => rejectCampaign()}
                        sx={{
                          "&:hover": {
                            color: "#FF4842",
                          },
                        }}
                      >
                        <Typography variant="caption">
                          {brandQuote ? "Decline Offer" : "Decline Invite"}{" "}
                          &nbsp;
                        </Typography>
                        <CancelIcon fontSize="small" color="error" />
                      </Stack>
                    </Grid>
                  }
                </Grid>
              }
            </>
          )}
        </Stack>
      </Stack>
      {openInterestModal && (
        <Modal
          open={openInterestModal}
          onClose={() => setOpenInterestModal(false)}
          aria-labelledby="interest-modal"
        >
          <Box
            sx={{
              padding: 3,
              backgroundColor: "#FFFFFF",
              width: isMobile ? 320 : 700,
              maxHeight: isMobile ? 600 : 850,
              marginX: "auto",
              marginTop: 5,
              borderRadius: 4,
              overflow: "scroll",
            }}
          >
            <Typography
              variant="h5"
              style={{ marginBottom: 20 }}
              align="center"
            >
              {activeFilter === "Invited"
                ? "Respond To Invite"
                : "Apply For Campaign"}
            </Typography>
            <Grid item xs={12} container justifyContent="space-between">
              <Grid item sx={12} md={5}>
                <img
                  src={coverPic}
                  alt="campaign-cover"
                  style={{
                    width: 300,
                    height: "auto",
                    objectFit: "contain",
                    margin: "auto",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={5} container alignItems="center">
                <Stack
                  direction="column"
                  sx={{
                    marginTop: "10px",
                    marginRight: "10px",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="subtitle1" color="grey">
                    The brand is interested in:
                  </Typography>
                  <FormControlLabel
                    control={<Checkbox checked={includesReels} />}
                    label="Reels"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={includesStories} />}
                    label="Stories"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={includesVideos} />}
                    label="TikTok Posts"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={includesPictures} />}
                    label="Feed Posts"
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ overflowY: "scroll" }}
              justifyContent="center"
            >
              <Typography
                variant="body1"
                sx={{
                  borderTop: 0.5,
                  borderBottom: 0.5,
                  marginY: 1,
                  width: "100%",
                }}
              >
                <b>Brief:</b> <br /> {description}
              </Typography>
              <TextField
                variant="standard"
                id="offer"
                label="My Offer"
                placeholder="£"
                type="number"
                onChange={(e) => setMyQuote(e.target.value)}
                sx={{ marginBottom: 3 }}
              />
            </Grid>
            {activeFilter === "New" && (
              <TextField
                fullWidth
                variant="standard"
                id="description"
                label="Description"
                multiline
                rows={4}
                placeholder="Please explain how you can contribute to the campaign"
                onChange={(e) => debouncedUserInput(e.target.value)}
                sx={{ marginBottom: 3 }}
              />
            )}
            <Grid
              item
              xs={12}
              container
              sx={{ overflowY: "scroll" }}
              justifyContent="center"
            >
              <Typography variant="h5" style={{ marginBottom: 20 }}>
                Please Read The Contract Carefully
              </Typography>
              <div dangerouslySetInnerHTML={{ __html: defaultContract }} />
              <br />
              {extendedContract && (
                <div dangerouslySetInnerHTML={{ __html: extendedContract }} />
              )}
            </Grid>
            <FormControlLabel
              label="I have read the terms and agree to them"
              control={
                <Checkbox
                  checked={agreed}
                  onClick={() => setAgreed(!agreed)}
                  sx={{ color: "#FFA3C7" }}
                />
              }
              sx={{
                width: "100%",
                justifyContent: "center",
                marginBottom: 1,
                // backgroundColor: "#FFA3C7",
                color: "#FFA3C7",
              }}
            />
            <Button
              variant="contained"
              sx={{ marginRight: 2, marginBottom: "25px" }}
              onClick={() =>
                activeFilter === "Invited" ? respondToInvite() : showInterest()
              }
              disabled={myQuote === 0 || !agreed}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenInterestModal(false)}
              sx={{ marginBottom: "25px" }}
            >
              Cancel
            </Button>
            <br />
            <Link
              to={`/customContract/${id}`}
              component={RouterLink}
              sx={{
                backgroundColor: "#FFA3C7",
                color: "#FFF",
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: 5,
                paddingRight: 5,
                margin: "auto",
              }}
            >
              Propose Changes
            </Link>
          </Box>
        </Modal>
      )}
      <ContractPopup
        contract={defaultContract}
        campaignContract={filteredContract}
      />
    </Card>
  );
}
