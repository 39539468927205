import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Stack,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  DialogContent,
  Snackbar,
  Alert,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { InsightsUploader } from "../sections/@dashboard/insights/InsightsUploader";
import ImageGallery from "../sections/@dashboard/insights/ImageGallery";
import { useMutation, useQuery } from "@apollo/client";
import SUBMIT_INSTAGRAM_STATS from "../graphql/mutations/submitInstagramStat";
import SUBMIT_TIKTOK_STATS from "../graphql/mutations/submitTiktokStat";
import GET_MY_INSTAGRAM_STATS from "../graphql/queries/getMyInstagramStats";
import GET_MY_TIKTOK_STATS from "../graphql/queries/getMyTiktokStats";
import { useNavigate } from "react-router-dom";
import HowToVideoPopup from "../components/HowToVideoPopup";
import { AGE_RANGE, CITIES, COUNTRIES } from "../constants";
import CurrencyType from "../components/fields/currencyType";
import { getCurrencySymbol } from "../components/fields/currencies";


// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "80vh",
  display: "flex",
  justifyContent: "Start",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));


export default function InsightsPage({ setLoggedIn }) {
  const nav = useNavigate();
  const [openInstagramUploader, setOpenInstagramUploader] = useState(false);
  const [openTiktokUploader, setOpenTiktokUploader] = useState(false)
  const [notification, setNotification] = useState(false);
  const [showInstagramImageGallary, setShowInstagramImageGallery] = useState(false);
  const [showTiktokImageGallary, setShowTiktokImageGallery] = useState(false);
  const [editInsta, setEditInsta] = useState(false);
  const [editTiktok, setEditTiktok] = useState(false);
  const [tiktokScreenshot, setTiktokScreenshot] = useState([]);
  const [instagramScreenshot, setInstagramScreenshot] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateInstagramFields = (stats) => {
    console.log(stats);
    setInstagramFields({
      ...instagramFields,
      userId: localStorage.getItem("currentUser"),
      following: stats["totalFollowers"] || 0,
      followingGrowthRate: stats["totalFollowersGrowthRate"] || "0",
      topCountry: stats["topCountry"] || "England",
      topCountryPercentage: stats["topCountryPercentage"] || 0,
      topCity: stats["topCity"] || "",
      topCityPercentage: stats["topCityPercentage"] || 0,
      femaleRatio: stats["femaleRatio"] || 0,
      maleRatio: stats["maleRatio"] || 0,
      accountsEngaged: stats["accountsEngaged"] || 0,
      accountsEngagedGrowthRate: stats["accountsEngagedGrowthRate"] || "0",
      accountVisibility: stats["accountVisibility"] || 0,
      accountsReached: stats["accountsReached"] || 0,
      accountsReachedGrowthRate: stats["accountsReachedGrowthRate"] || "0",
      topAgeRange: stats["topAgeRange"] || "",
    });
  };

  const updateTikTokFields = (stats) => {
    console.log(stats);
    setTiktokFields({
      ...tiktokFields,
      userId: localStorage.getItem("currentUser"),
      following: stats["totalFollowers"] || 0,
      topCountry: stats["topCountry"] || "England",
      topCountryPercentage: stats["topCountryPercentage"] || 0,
      topCity: stats["topCity"] || "",
      topCityPercentage: stats["topCityPercentage"] || 0,
      femaleRatio: stats["femaleRatio"] || 0,
      maleRatio: stats["maleRatio"] || 0,
      accountVisibility: stats["accountVisibility"] || 0,
      videoViews: stats["videoViews"] || 0,
      videoViewsGrowthRate: stats["videoViewsGrowthRate"] || '0',
      profileViews: stats["profileViews"] || 0,
      profileViewsGrowthRate: stats["profileViewsGrowthRate"] || '0',
      likes: stats["likes"] || 0,
      likesGrowthRate: stats["likesGrowthRate"] || '0',
      comments: stats["comments"] || 0,
      commentsGrowthRate: stats["commentsGrowthRate"] || '0',
      topAgeRange: stats["topAgeRange"] || "",
    });
  };

  const [instagramFields, setInstagramFields] = useState({
    userId: localStorage.getItem("currentUser"),
    following: 0,
    followingGrowthRate: "0",
    topCountry: "England",
    topCountryPercentage: 0,
    topCity: "",
    topCityPercentage: 0,
    femaleRatio: 0,
    maleRatio: 0,
    accountsEngaged: 0,
    accountsEngagedGrowthRate: "0",
    accountVisibility: 0,
    accountsReached: 0,
    accountsReachedGrowthRate: "0",
    topAgeRange: "",
    link: [],
  });

  const [tiktokFields, setTiktokFields] = useState({
    userId: localStorage.getItem("currentUser"),
    following: 0,
    topCountry: "England",
    topCountryPercentage: 0,
    topCity: "",
    topCityPercentage: 0,
    femaleRatio: 0,
    maleRatio: 0,
    accountVisibility: 0,
    videoViews: 0,
    videoViewsGrowthRate: "0",
    profileViews: 0,
    profileViewsGrowthRate: "0",
    likes: 0,
    likesGrowthRate: "0",
    comments: 0,
    commentsGrowthRate: "0",
    commission: 0,
    commissionType: "USD",
    topAgeRange: "",
    link: [],
  });

  const { data: myInstagramStats } = useQuery(GET_MY_INSTAGRAM_STATS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      if (res.getMyInstagramStats === null) {
        handleOpenModal();
      }
      res.getMyInstagramStats &&
        setInstagramFields({
          ...instagramFields,
          userId: localStorage.getItem("currentUser"),
          following: res.getMyInstagramStats.following || 0,
          followingGrowthRate: res.getMyInstagramStats.followingGrowthRate || "0",
          topCountry: res.getMyInstagramStats.topCountry || "England",
          topCountryPercentage: res.getMyInstagramStats.topCountryPercentage || 0,
          topCity: res.getMyInstagramStats.topCity || "",
          topCityPercentage: res.getMyInstagramStats.topCityPercentage || 0,
          femaleRatio: res.getMyInstagramStats.femaleRatio || 0,
          maleRatio: res.getMyInstagramStats.maleRatio || 0,
          accountsEngaged: res.getMyInstagramStats.accountsEngaged || 0,
          accountsEngagedGrowthRate: res.getMyInstagramStats.accountsEngagedGrowthRate || "0",
          accountVisibility: res.getMyInstagramStats.accountVisibility,
          accountsReached: res.getMyInstagramStats.accountsReached || 0,
          accountsReachedGrowthRate: res.getMyInstagramStats.accountsReachedGrowthRate || "0",
          topAgeRange: res.getMyInstagramStats.topAgeRange || "",
          link: res.getMyInstagramStats.link[0].split("|") || [],
        });
    },
  });

  const { data: myTiktokStats } = useQuery(GET_MY_TIKTOK_STATS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      if (res.getMyTiktokStats === null) {
        handleOpenModal();
      }
      res.getMyTiktokStats &&
        setTiktokFields({
          ...tiktokFields,
          userId: localStorage.getItem("currentUser"),
          following: res.getMyTiktokStats?.following || 0,
          topCountry: res.getMyTiktokStats.topCountry || "England",
          topCountryPercentage: res.getMyTiktokStats.topCountryPercentage || 0,
          topCity: res.getMyTiktokStats.topCity || "",
          topCityPercentage: res.getMyTiktokStats.topCityPercentage || 0,
          femaleRatio: res.getMyTiktokStats.femaleRatio || 0,
          maleRatio: res.getMyTiktokStats.maleRatio || 0,
          accountVisibility: res.getMyTiktokStats.accountVisibility,
          videoViews: res.getMyTiktokStats.videoViews || 0,
          videoViewsGrowthRate: res.getMyTiktokStats.videoViewsGrowthRate || "0",
          profileViews: res.getMyTiktokStats.profileViews || 0,
          profileViewsGrowthRate: res.getMyTiktokStats.profileViewsGrowthRate || 0,
          likes: res.getMyTiktokStats.likes || 0,
          likesGrowthRate: res.getMyTiktokStats.likesGrowthRate || "0",
          comments: res.getMyTiktokStats.comments || 0,
          commentsGrowthRate: res.getMyTiktokStats.commentsGrowthRate || "0",
          commission: res.getMyTiktokStats.commission || 0,
          commissionType: res.getMyTiktokStats.commissionType || "USD",
          topAgeRange: res.getMyTiktokStats.topAgeRange || "",
          link: res.getMyTiktokStats.link[0].split("|") || [],
        });
    },
  });

  const [instagramSubmit] = useMutation(SUBMIT_INSTAGRAM_STATS, {
    onCompleted: () => {
      setNotification(true);
      setInstagramFields({
        ...instagramFields,
        userId: localStorage.getItem("currentUser"),
        following: 0,
        followingGrowthRate: "0",
        topCountry: "England",
        topCountryPercentage: 0,
        topCity: "",
        topCityPercentage: 0,
        femaleRatio: 0,
        maleRatio: 0,
        accountsEngaged: 0,
        accountsEngagedGrowthRate: "0",
        accountVisibility: 0,
        accountsReached: 0,
        accountsReachedGrowthRate: "0",
        topAgeRange: "",
        link: [],
      });
      setInstagramScreenshot("");
      nav("/insights");
    },
    refetchQueries: ["getMyInstagramStats"],
  });

  const [tiktokSubmit] = useMutation(SUBMIT_TIKTOK_STATS, {
    onCompleted: () => {
      setNotification(true);
      setTiktokFields({
        ...tiktokFields,
        userId: localStorage.getItem("currentUser"),
        following: 0,
        topCountry: "England",
        topCountryPercentage: 0,
        topCity: "",
        topCityPercentage: 0,
        femaleRatio: 0,
        maleRatio: 0,
        accountVisibility: 0,
        videoViews: 0,
        videoViewsGrowthRate: "0",
        profileViews: 0,
        profileViewsGrowthRate: "0",
        likes: 0,
        likesGrowthRate: "0",
        comments: 0,
        commentsGrowthRate: "0",
        commission: "0",
        commissionType: "USD",
        topAgeRange: "",
        link: [],
      });
      setTiktokScreenshot([]);
      nav("/insights");
    },
    refetchQueries: ["getMyTiktokStats"],
  });

  const handleInstagramSubmission = () => {
    instagramSubmit({
      variables: {
        userId: instagramFields.userId,
        link: instagramScreenshot.join("|").toString(),
        following: parseInt(instagramFields.following),
        followingGrowthRate: instagramFields.followingGrowthRate.toString() || "0",
        topCountry: instagramFields.topCountry,
        topCountryPercentage: parseInt(instagramFields.topCountryPercentage),
        topCity: instagramFields.topCity,
        topCityPercentage: parseInt(instagramFields.topCityPercentage),
        femaleRatio: parseInt(instagramFields.femaleRatio),
        maleRatio: parseInt(instagramFields.maleRatio),
        accountsEngaged: parseInt(instagramFields.accountsEngaged),
        accountsEngagedGrowthRate: instagramFields.accountsEngagedGrowthRate.toString() || "0",
        accountVisibility: parseInt(instagramFields.accountVisibility),
        accountsReached: parseInt(instagramFields.accountsReached),
        accountsReachedGrowthRate: instagramFields.accountsReachedGrowthRate.toString() || "0",
        topAgeRange: instagramFields.topAgeRange,
      },
    });
  };

  const handleTiktokSubmission = () => {
    tiktokSubmit({
      variables: {
        userId: tiktokFields.userId,
        link: tiktokScreenshot.join("|").toString(),
        following: parseInt(tiktokFields.following),
        topCountry: tiktokFields.topCountry,
        topCountryPercentage: parseInt(tiktokFields.topCountryPercentage),
        topCity: tiktokFields.topCity,
        topCityPercentage: parseInt(tiktokFields.topCityPercentage),
        femaleRatio: parseInt(tiktokFields.femaleRatio),
        maleRatio: parseInt(tiktokFields.maleRatio),
        accountVisibility: parseInt(tiktokFields.accountVisibility),
        videoViews: parseInt(tiktokFields.videoViews),
        videoViewsGrowthRate: tiktokFields.videoViewsGrowthRate,
        profileViews: parseInt(tiktokFields.profileViews),
        profileViewsGrowthRate: tiktokFields.profileViewsGrowthRate,
        likes: parseInt(tiktokFields.likes),
        likesGrowthRate: tiktokFields.likesGrowthRate,
        comments: parseInt(tiktokFields.comments),
        commentsGrowthRate: tiktokFields.commentsGrowthRate,
        commission: tiktokFields.commission,
        commission: tiktokFields.commission.toString() || "0",
        topAgeRange: tiktokFields.topAgeRange,
      },
    });
    nav("/insights");
  };


  const setCurrencyType = (data)=>{
    setTiktokFields({
      ...tiktokFields,
      commissionType: data,
    })
  }

  return (
    <>
      <Helmet>
        <title> Insights | Authentic Influencers </title>
      </Helmet>

      <div
        style={{
          textAlign: "center",
          margin: "1em",
        }}
      >

      </div>

      <StyledRoot>
        <Snackbar open={notification} autoHideDuration={6000}>
          <Alert severity="success" sx={{ width: "100%" }}>
            Submission Successful
          </Alert>
        </Snackbar>

        <StyledSection>
          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                Enter Instagram Insights
                <HowToVideoPopup title="See How to Upload Insights" description="Watch this video to understand how to upload insights" youtubeId="cxjsi1v09o4"/>
              </Typography>
              {myInstagramStats?.getMyInstagramStats && !editInsta && (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setEditInsta(true);
                    setShowInstagramImageGallery(true);
                  }}
                >
                  Edit
                </Button>
              )}
              {editInsta && (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => setEditInsta(false)}
                >
                  Cancel
                </Button>
              )}
              <Divider sx={{ my: 3 }} />
              {myInstagramStats?.getMyInstagramStats && !editInsta ? (
                <Stack spacing={3}>
                  <Stack direction="row">
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`Following: ${instagramFields.following}`}
                      disabled
                    />
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`GrowthRate: ${instagramFields.followingGrowthRate}`}
                      disabled
                    />
                  </Stack>
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top Country: ${instagramFields.topCountry}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top Country Percentage: ${instagramFields.topCountryPercentage}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top City: ${instagramFields.topCity}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top City Percentage: ${instagramFields.topCityPercentage}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Female Ratio: ${instagramFields.femaleRatio}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Male Ratio: ${instagramFields.maleRatio}`}
                    disabled
                  />
                  <Stack direction="row" spacing={3}>
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`Accounts Engaged: ${instagramFields.accountsEngaged}`}
                      disabled
                    />
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`GrowthRate: ${instagramFields.accountsEngagedGrowthRate}`}
                      disabled
                    />
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`Accounts Reached: ${instagramFields.accountsReached}`}
                      disabled
                    />
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`GrowthRate: ${instagramFields.accountsReachedGrowthRate}`}
                      disabled
                    />
                  </Stack>

                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top Age Range: ${instagramFields.topAgeRange}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Account Visibility: ${instagramFields.accountVisibility}`}
                    disabled
                  />
                  <DialogContent>
                    <ImageGallery images={instagramFields.link} />
                  </DialogContent>
                </Stack>
              ) : (
                <Stack spacing={3}>
                  <Stack direction="row" spacing={3}>
                    <TextField
                      required
                      name="following"
                      label="Following"
                      type="number"
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          following: e.target.value,
                        })
                      }
                      value={instagramFields.following}
                    />
                    <TextField
                      required
                      name="followingGrowthRate"
                      label="Following Growth Rate"
                      type="text"
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          followingGrowthRate: e.target.value.toString(),
                        })
                      }
                      value={instagramFields.followingGrowthRate}
                    />
                  </Stack>

                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="country-select-label">
                      Top Country
                    </InputLabel>
                    <Select
                      labelId="country-select-label"
                      id="country-select"
                      label="Top Country"
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          topCountry: e.target.value,
                        })
                      }
                      value={instagramFields.topCountry}
                    >
                      {COUNTRIES.map((country, index) => (
                        <MenuItem value={country} key={`${country}-${index}`}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    name="topCountryPercentage"
                    label="Top Country Percentage"
                    placeholder={instagramFields?.topCountryPercentage.toString()}
                    type="number"
                    onChange={(e) =>
                      setInstagramFields({
                        ...instagramFields,
                        topCountryPercentage: e.target.value,
                      })
                    }
                    value={instagramFields.topCountryPercentage}
                  />
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="city-select-label">Select City</InputLabel>
                    <Select
                      labelId="city-select-label"
                      id="city-select"
                      label="Top City"
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          topCity: e.target.value,
                        })
                      }
                      value={instagramFields.topCity}
                    >
                      {[instagramFields.topCountry]?.map(
                        (city, index) => (
                          <MenuItem value={city} key={`${city}-${index}`}>
                            {city}
                          </MenuItem>
                        )
                      )}
                      {CITIES[instagramFields.topCountry].map((city, index) => (
                        <MenuItem value={city} key={`${city}-${index}`}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    name="topCityPercentage"
                    label="Top City Percentage"
                    type="number"
                    placeholder={instagramFields?.topCityPercentage.toString()}
                    onChange={(e) =>
                      setInstagramFields({
                        ...instagramFields,
                        topCityPercentage: e.target.value,
                      })
                    }
                    value={instagramFields.topCityPercentage}
                  />
                  <TextField
                    name="femaleRatio"
                    label="Female Ratio"
                    placeholder={instagramFields?.femaleRatio.toString()}
                    onChange={(e) =>
                      setInstagramFields({
                        ...instagramFields,
                        femaleRatio: e.target.value,
                      })
                    }
                    value={instagramFields.femaleRatio}
                  />
                  <TextField
                    name="maleRatio"
                    label="Male Ratio"
                    placeholder={instagramFields?.maleRatio.toString()}
                    onChange={(e) =>
                      setInstagramFields({
                        ...instagramFields,
                        maleRatio: e.target.value,
                      })
                    }
                    value={instagramFields.maleRatio}
                  />
                  <Stack direction="row" spacing={3}>
                    <TextField
                      name="accountsEngaged"
                      label="Accounts Engaged"
                      type="number"
                      placeholder={instagramFields?.accountsEngaged.toString()}
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          accountsEngaged: e.target.value,
                        })
                      }
                      value={instagramFields.accountsEngaged}
                    />
                    <TextField
                      name="accountsEngagedGrowthRate"
                      label="Accounts Engaged Growth Rate"
                      type="text"
                      placeholder={instagramFields?.accountsEngagedGrowthRate}
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          accountsEngagedGrowthRate: e.target.value,
                        })
                      }
                      value={instagramFields.accountsEngagedGrowthRate}
                    />
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <TextField
                      name="accountsReached"
                      label="Accounts Reached"
                      type="number"
                      placeholder={instagramFields?.accountsReached.toString()}
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          accountsReached: e.target.value,
                        })
                      }
                      value={instagramFields.accountsReached}
                    />
                    <TextField
                      name="accountsReachedGrowthRate"
                      label="Accounts Reached Growth Rate"
                      type="text"
                      placeholder={instagramFields?.accountsReachedGrowthRate}
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          accountsReachedGrowthRate: e.target.value,
                        })
                      }
                      value={instagramFields.accountsReachedGrowthRate}
                    />
                  </Stack>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      Top Age Range
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Campaign"
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          topAgeRange: e.target.value,
                        })
                      }
                      value={instagramFields.topAgeRange}
                    >
                      {AGE_RANGE.map((age, index) => (
                        <MenuItem value={age} key={`${age}-${index}`}>
                          {age}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="account-visibility-select-label">
                      Account Visibility
                    </InputLabel>
                    <Select
                      labelId="account-visibility-select-label"
                      id="account-visibility-select"
                      label="Account Visibility"
                      onChange={(e) =>
                        setInstagramFields({
                          ...instagramFields,
                          accountVisibility: e.target.value,
                        })
                      }
                      value={instagramFields.accountVisibility}
                    >
                      <MenuItem value={0}>Public</MenuItem>
                      <MenuItem value={1}>Private</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              )}
              {(myInstagramStats?.getMyInstagramStats === null ||
                editInsta) && (
                  <>
                    <Button
                      variant="contained"
                      sx={{ marginTop: 2 }}
                      onClick={() => setOpenInstagramUploader(true)}
                    >
                      Profile Screenshot
                    </Button>
                    {showInstagramImageGallary && (
                      <DialogContent>
                        <ImageGallery images={instagramScreenshot} />
                      </DialogContent>
                    )}
                    <InsightsUploader
                      open={openInstagramUploader}
                      setOpen={setOpenInstagramUploader}
                      setInstagramPictureUrls={setInstagramScreenshot}
                      setTiktokPictureUrls={false}
                      setImageGallery={setShowInstagramImageGallery}
                      fields={new Array(instagramFields)}
                      setFields={updateInstagramFields}
                    />
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      value={instagramScreenshot}
                      onClick={() => handleInstagramSubmission()}
                      sx={{ marginTop: 3 }}
                      disabled={instagramScreenshot.length === [].length}
                    >
                      Submit
                    </LoadingButton>
                  </>
                )}
            </StyledContent>
          </Container>
        </StyledSection>

        <StyledSection>
          <Container maxWidth="sm">
            <StyledContent>

              <Typography variant="h4" gutterBottom>
                Enter TikTok Insights
                <HowToVideoPopup title="See How to Upload Insights" description="Watch this video to understand how to upload insights" youtubeId="FWKQxpJb_6g"/>
              </Typography>
              {myTiktokStats?.getMyTiktokStats && !editTiktok && (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setEditTiktok(true);
                    setShowTiktokImageGallery(true);
                  }}
                >
                  Edit
                </Button>
              )}
              {editTiktok && (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => setEditTiktok(false)}
                >
                  Cancel
                </Button>
              )}
              <Divider sx={{ my: 3 }} />
              {myTiktokStats?.getMyTiktokStats && !editTiktok ? (
                <Stack spacing={3}>
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Following: ${tiktokFields.following}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top Country: ${tiktokFields.topCountry}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top Country Percentage: ${tiktokFields.topCountryPercentage}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top City: ${tiktokFields.topCity}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top City Percentage: ${tiktokFields.topCityPercentage}`}
                    disabled
                  />
                  <Stack direction="row" spacing={3}>
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`Female Ratio: ${tiktokFields.femaleRatio}`}
                      disabled
                    />
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`Male Ratio: ${tiktokFields.maleRatio}`}
                      disabled
                    />
                  </Stack>
                  <Stack direction="row" spacing={3}>
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`Post Views: ${tiktokFields.videoViews}`}
                      disabled
                    />
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`GrowthRate: ${tiktokFields.videoViewsGrowthRate}`}
                      disabled
                    />
                  </Stack>
                  {/* <Stack direction="row" spacing={3}> */}
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`Profile Views: ${tiktokFields.profileViews}`}
                      disabled
                    />
                    {/* <TextField
                      required
                      name="following"
                      type="text"
                      value={`GrowthRate: ${tiktokFields.profileViewsGrowthRate}`}
                      disabled
                    /> */}
                  {/* </Stack> */}
                  <Stack direction="row" spacing={3}>
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`Likes: ${tiktokFields.likes}`}
                      disabled
                    />
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`GrowthRate: ${tiktokFields.likesGrowthRate}`}
                      disabled
                    />
                  </Stack>
                  {/* <Stack direction="row" spacing={3}> */}
                    <TextField
                      required
                      name="following"
                      type="text"
                      value={`Comments: ${tiktokFields.comments}`}
                      disabled
                    />
                    {/* <TextField
                      required
                      name="following"
                      type="text"
                      value={`GrowthRate: ${tiktokFields.commentsGrowthRate}`}
                      disabled
                    /> */}
                  {/* </Stack> */}
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Top Age Range: ${tiktokFields.topAgeRange}`}
                    disabled
                  />
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Account Visibility: ${tiktokFields.accountVisibility}`}
                    disabled
                  />
                  <div style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}>
                    How much commission did you generate in the last 30 days?
                  </div>
                  <TextField
                    required
                    name="following"
                    type="text"
                    value={`Commission: ${getCurrencySymbol(tiktokFields.commissionType)}${tiktokFields.commission}`}
                    disabled
                  />
                  <DialogContent>
                    <ImageGallery images={tiktokFields.link} />
                  </DialogContent>
                </Stack>
              ) : (
                <Stack spacing={3}>
                  <TextField
                    required
                    name="following"
                    label="Following"
                    type="number"
                    onChange={(e) =>
                      setTiktokFields({
                        ...tiktokFields,
                        following: e.target.value,
                      })
                    }
                    value={tiktokFields.following}
                  />
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="country-select-label">
                      Top Country
                    </InputLabel>
                    <Select
                      required
                      labelId="country-select-label"
                      id="country-select"
                      label="Top Country"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          topCountry: e.target.value,
                        })
                      }
                      value={tiktokFields.topCountry}
                    >
                      {COUNTRIES.map((country, index) => (
                        <MenuItem value={country} key={`${country}-${index}`}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    name="topCountryPercentage"
                    label="Top Country Percentage"
                    type="number"
                    onChange={(e) =>
                      setTiktokFields({
                        ...tiktokFields,
                        topCountryPercentage: e.target.value,
                      })
                    }
                    value={tiktokFields.topCountryPercentage}
                  />
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="city-select-label">Select City</InputLabel>
                    <Select
                      labelId="city-select-label"
                      id="city-select"
                      label="Top City"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          topCity: e.target.value,
                        })
                      }
                      value={tiktokFields?.topCity}
                    >
                      {CITIES[tiktokFields.topCountry].map((city, index) => (
                        <MenuItem value={city} key={`${city}-${index}`}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    name="topCityPercentage"
                    label="Top City Percentage"
                    type="number"
                    onChange={(e) =>
                      setTiktokFields({
                        ...tiktokFields,
                        topCityPercentage: e.target.value,
                      })
                    }
                    value={tiktokFields.topCityPercentage}
                  />
                  <Stack direction="row" spacing={3}>
                    <TextField
                      name="femaleRatio"
                      label="Female Ratio"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          femaleRatio: e.target.value,
                        })
                      }
                      value={tiktokFields.femaleRatio}
                    />
                    <TextField
                      name="maleRatio"
                      label="Male Ratio"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          maleRatio: e.target.value,
                        })
                      }
                      value={tiktokFields.maleRatio}
                    />
                  </Stack>

                  <Stack direction="row" spacing={3}>
                    <TextField
                      name="videoViews"
                      label="Post Views"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          videoViews: e.target.value,
                        })
                      }
                      value={tiktokFields.videoViews}
                    />
                    <TextField
                      name="videoViewsGrowthRate"
                      label="Post Views Growth Rate"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          videoViewsGrowthRate: e.target.value,
                        })
                      }
                      value={tiktokFields.videoViewsGrowthRate}
                    />
                  </Stack>
                  {/* <Stack direction="row" spacing={3}> */}
                    <TextField
                      name="profileViews"
                      label="Profile Views"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          profileViews: e.target.value,
                        })
                      }
                      value={tiktokFields.profileViews}
                    />
                    {/* <TextField
                      name="profileViewsGrowthRate"
                      label="Profile Views Growth Rate"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          profileViewsGrowthRate: e.target.value,
                        })
                      }
                      value={tiktokFields.profileViewsGrowthRate}
                    /> */}
                  {/* </Stack> */}
                  <Stack direction="row" spacing={3}>
                    <TextField
                      name="likes"
                      label="Likes"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          likes: e.target.value,
                        })
                      }
                      value={tiktokFields.likes}
                    />
                    <TextField
                      name="likesGrowthRate"
                      label="Likes Growth Rate"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          likesGrowthRate: e.target.value,
                        })
                      }
                      value={tiktokFields.likesGrowthRate}
                    />
                  </Stack>
                  {/* <Stack direction="row" spacing={3}> */}
                    <TextField
                      name="comments"
                      label="Comments"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          comments: e.target.value,
                        })
                      }
                      value={tiktokFields.comments}
                    />
                    {/* <TextField
                      name="commentsGrowthRate"
                      label="Comments Growth Rate"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          commentsGrowthRate: e.target.value,
                        })
                      }
                      value={tiktokFields.commentsGrowthRate}
                    />
                  </Stack> */}
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      Top Age Range
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Campaign"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          topAgeRange: e.target.value,
                        })
                      }
                      value={tiktokFields.topAgeRange}
                    >
                      {AGE_RANGE.map((age, index) => (
                        <MenuItem value={age} key={`${age}-${index}`}>
                          {age}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ marginTop: 2 }}>
                    <InputLabel id="account-visibility-select-label">
                      Account Visibility
                    </InputLabel>
                    <Select
                      labelId="account-visibility-select-label"
                      id="account-visibility-select"
                      label="Account Visibility"
                      onChange={(e) =>
                        setTiktokFields({
                          ...tiktokFields,
                          accountVisibility: e.target.value,
                        })
                      }
                      value={tiktokFields.accountVisibility || 0}
                    >
                      <MenuItem value={0}>Public</MenuItem>
                      <MenuItem value={1}>Private</MenuItem>
                    </Select>
                  </FormControl>
                  <div style={{display: "flex", marginTop: "20px"}}>
                    <CurrencyType currencyType={tiktokFields.commissionType} setCurrencyType={setCurrencyType} width={"27%"} />
                    <TextField
                        name="commission"
                        label="Commission"
                        onChange={(e) =>
                          setTiktokFields({
                            ...tiktokFields,
                            commission: e.target.value,
                          })
                        }
                        value={tiktokFields.commission}
                      />
                    </div>
                </Stack>
              )}
              {(myTiktokStats?.getMyTiktokStats === null || editTiktok) && (
                <>
                  <Button
                    variant="contained"
                    sx={{ marginTop: 2 }}
                    onClick={() => setOpenTiktokUploader(true)}
                  >
                    Profile Screenshot
                  </Button>
                  {showTiktokImageGallary && (
                    <DialogContent>
                      <ImageGallery images={tiktokScreenshot} />
                    </DialogContent>
                  )}
                  <InsightsUploader
                    open={openTiktokUploader}
                    setOpen={setOpenTiktokUploader}
                    setTiktokPictureUrls={setTiktokScreenshot}
                    setInstagramPictureUrls={false}
                    setImageGallery={setShowTiktokImageGallery}
                    fields={new Array(tiktokFields)}
                    setFields={updateTikTokFields}
                  />
                  <>
                    {tiktokScreenshot && <Grid container spacing={2} style={{ padding: '20px 0px' }}>
                      {tiktokScreenshot.map((img) => {
                        return (
                          <Grid item xs={6} style={{ padding: '10px' }}>
                            <img width='200' height='200'
                              src={img}
                              alt="Image not found"
                            />
                          </Grid>
                        )
                      })}
                    </Grid>}
                  </>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    value={tiktokScreenshot}
                    onClick={() => handleTiktokSubmission()}
                    sx={{ marginTop: 3 }}
                    disabled={tiktokScreenshot.length === [].length}
                  >
                    Submit
                  </LoadingButton>
                </>
              )}
            </StyledContent>
          </Container>
        </StyledSection>
      </StyledRoot>
    </>
  );
}
