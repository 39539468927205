import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Container,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CampaignDetailBanner from "../sections/@dashboard/campaigns/campaignDetailBanner";
import { CampaignTaskDetails } from "../sections/@dashboard/campaigns";
import { useQuery, useMutation } from "@apollo/client";
import GET_BRAND from "../graphql/queries/getBrandInfo"; // Replace with your actual query import
import SHOW_PAID_INTEREST from "../graphql/mutations/interestedInPaidCampaign"; // Replace with your actual mutation import
import SAVED_SIGNATURE from "../graphql/queries/getSavedSignature";
import GET_CAMPAIGN_DETAILS from "../graphql/queries/getCampaignDetails";
import GET_CONTRACT from "../graphql/queries/getContract";

export default function CampaignDetailsPageWithTask() {
  const { id } = useParams();

  const [defaultContract, setDefaultContract] = useState("");
  const [brandSignature, setBrandSignature] = useState(null);
  const [influencerSignature, setInfluencerSignature] = useState(null);
  const [influencerSignature1, setInfluencerSignature1] = useState(null);
  const [adminSignature, setAdminSignature] = useState(null);
  const [brandName, setBrandName] = useState("");
  const [brandDate, setBrandDate] = useState("");
  const [influencerName, setInfluencerName] = useState("");
  const [influencerDate, setInfluencerDate] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminDate, setAdminDate] = useState("");
  const [campaignContract, setCampaignContract] = useState();

  const [myQuote, setMyQuote] = useState(0);
  const [currencyType, setCurrencyType] = useState("");
  const [myDescription, setDescription] = useState("");
  const [influencerCommissionModel, setInfluencerCommissionModel] =
    useState(false);
  const [openInterestModal, setOpenInterestModal] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [influencerContract, setInfluencerContract] = useState(null);
  const {
    loading: savedSignatureLoading,
    data: savedSignatureData,
    refetch: refetchSavedSignature,
  } = useQuery(SAVED_SIGNATURE, {
    variables: { campaignId: id, userId: localStorage.getItem("currentUser") },
    onCompleted: (data) => {
      if (data.getSavedSignature) {
        const {
          brand_signature,
          brand_name,
          brand_date,
          influencer_signature,
          influencer_name,
          influencer_date,
          admin_signature,
          admin_name,
          admin_date,
        } = data.getSavedSignature;
        setBrandSignature(brand_signature);
        setBrandName(brand_name);
        setBrandDate(brand_date);
        setInfluencerSignature(influencer_signature);
        setInfluencerName(influencer_name);
        setInfluencerDate(influencer_date);
        setAdminSignature(admin_signature);
        setAdminName(admin_name);
        setAdminDate(admin_date);
      }
    },
    onError: (error) => {
      console.error("Error fetching saved signature data:", error);
    },
  });

  async function loadInfluencerContract(contractLink) {
    try {
      const response = await fetch(contractLink);
      const data = await response.text();
      setInfluencerContract(data);
    } catch (error) {
      console.error("Error loading contract:", error);
    }
  }

  const { data1 } = useQuery(GET_CONTRACT, {
    variables: {
      campaignId: id,
      userId: localStorage.getItem("currentUser").toString(),
    },
    onCompleted: (res) => {
      setInfluencerContract(res.getContract);
      loadInfluencerContract(res.getContract);
    },
    onError: (error) => {
      console.error("Error fetching contract data:", error);
    },
  });
  const { data } = useQuery(GET_CAMPAIGN_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      campaignId: id,
    },
    onCompleted: (res) => {
      setCampaign(res.getCampaignDetails);
    },
  });

  const {
    loading: brandLoading,
    error: brandError,
    data: brandData,
    refetch: refetchBrand,
  } = useQuery(GET_BRAND, {
    variables: {
      userId: localStorage.getItem("currentUser"),
      campaignId: id,
      influencerId: localStorage.getItem("currentUser"),
    },
    onCompleted: (data) => {
      if (data.getBrand) {
        setInfluencerSignature1(data.getBrand.signature);
      }
    },
    onError: (error) => {
      console.error("Error fetching brand data:", error);
    },
  });

  const [showInterest, { loading: interestLoading }] = useMutation(
    SHOW_PAID_INTEREST,
    {
      onCompleted: () => {
        setMyQuote(0);
        setCurrencyType("");
        setDescription("");
        setOpenInterestModal(false);
        setSubmitting(false);
      },
      refetchQueries: [
        "getCandidateCampaignsForInfluencer",
        "getInterestedCampaignsForInfluencer",
        "getInvitedCampaignsForInfluencer",
        "getCampaignsForInfluencer",
      ],
      onError: (error) => {
        console.error("Error showing interest:", error);
        setSubmitting(false);
      },
    }
  );

  const loadCampaignContract = async () => {
    if (campaign.contract) {
      await fetch(campaign.contract)
        .then((r) => {
          r.text().then((d) => {
            setCampaignContract(d);
          });
        })
        .catch((error) => {
          console.error("Error fetching campaign contract:", error);
        });
    } else {
      console.error("Campaign contract is null or undefined");
    }
  };

  useEffect(() => {
    if (campaign) {
      loadContract();
      loadCampaignContract();
    }
  }, [campaign]);

  const loadContract = async () => {
    try {
      const response = await fetch(
        "https://authentic-pps.s3.eu-west-2.amazonaws.com/authentic_default_contract.txt"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch default contract");
      }
      const data = await response.text();
      setDefaultContract(data);
    } catch (error) {
      console.error("Error fetching default contract:", error);
    }
  };
  const [isNewSignature, setIsNewSignature] = useState(false);

  const handleAddSignature = (type) => {
    if (type === "influencer" && brandData.getBrand.signature) {
      setIsNewSignature(true);
      setInfluencerSignature(influencerSignature1);
    }
  };

  const handleRemoveSignature = (type) => {
    if (type === "brand") setBrandSignature(null);
    else if (type === "influencer") setInfluencerSignature(null);
    else if (type === "admin") setAdminSignature(null);
  };

  const handleSubmitSignatures = () => {
    setSubmitting(true);
    showInterest({
      variables: {
        userId: localStorage.getItem("currentUser"),
        campaignId: id,
        influencerQuote: parseInt(myQuote),
        influencerQuoteType: currencyType,
        description: myDescription,
        commissionModel: influencerCommissionModel,
        brandSignature,
        brandName,
        brandDate,
        influencerSignature,
        influencerName,
        influencerDate,
        adminSignature,
        adminName,
        adminDate,
      },
    });
  };

  if (brandLoading || savedSignatureLoading || interestLoading) {
    return (
      <Container style={{ textAlign: "center", marginTop: "20px" }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>Campaigns</title>
      </Helmet>

      <Container>
        <CampaignDetailBanner campaignId={id} />
        <CampaignTaskDetails campaignId={id} />

        <>
          <div
            dangerouslySetInnerHTML={
              { __html: defaultContract } || { __html: "Loading..." }
            }
          />
          {campaignContract && (
            <div>
              <b>
                <br />
                Additional Clauses by brand
              </b>
              <div
                dangerouslySetInnerHTML={
                  { __html: campaignContract } || { __html: "Loading..." }
                }
              />
              <b>
                <br />
                Additional Clauses by Influencer
              </b>
              <div
                dangerouslySetInnerHTML={
                  { __html: influencerContract } || { __html: "Loading..." }
                }
              />
            </div>
          )}
        </>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "flex-start",
          }}
        >
          <div style={{ textAlign: "center", width: "30%" }}>
            <Typography variant="subtitle2">Brand Signature</Typography>
            <div
              style={{
                height: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {brandSignature ? (
                <>
                  <img
                    src={brandSignature}
                    alt="Brand Signature"
                    style={{ maxWidth: 150, maxHeight: 150, marginBottom: 10 }}
                  />
                </>
              ) : (
                <label>No signature</label>
              )}
            </div>
            {brandSignature && (
              <>
                <TextField
                  id="brand-name"
                  label="Name"
                  variant="standard"
                  value={brandName}
                  style={{ display: "block", marginBottom: 10 }}
                />
                <TextField
                  id="brand-date"
                  label="Date"
                  type="date"
                  variant="standard"
                  value={brandDate}
                  style={{ display: "block", marginBottom: 10 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          </div>
          <div style={{ textAlign: "center", width: "30%" }}>
            <Typography variant="subtitle2">Influencer Signature</Typography>
            <div
              style={{
                height: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {influencerSignature ? (
                <>
                  <img
                    src={influencerSignature}
                    alt="Influencer Signature"
                    style={{ maxWidth: 150, maxHeight: 150, marginBottom: 10 }}
                  />
                  {isNewSignature && (
                    <Button onClick={() => handleRemoveSignature("influencer")}>
                      Remove
                    </Button>
                  )}{" "}
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAddSignature("influencer")}
                >
                  Add Signature
                </Button>
              )}
            </div>
            {influencerSignature && (
              <>
                <TextField
                  id="influencer-name"
                  label="Name"
                  variant="standard"
                  value={influencerName}
                  onChange={(e) => {
                    isNewSignature && setInfluencerName(e.target.value);
                  }}
                  style={{ display: "block", marginBottom: 10 }}
                />
                <TextField
                  id="influencer-date"
                  label="Date"
                  type="date"
                  variant="standard"
                  value={influencerDate}
                  onChange={(e) => {
                    isNewSignature && setInfluencerDate(e.target.value);
                  }}
                  style={{ display: "block", marginBottom: 10 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          </div>
          <div style={{ textAlign: "center", width: "30%" }}>
            <Typography variant="subtitle2">Admin Signature</Typography>
            <div
              style={{
                height: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {adminSignature ? (
                <>
                  <img
                    src={adminSignature}
                    alt="Admin Signature"
                    style={{ maxWidth: 150, maxHeight: 150, marginBottom: 10 }}
                  />
                </>
              ) : (
                <label>No signature</label>
              )}
            </div>
            {adminSignature && (
              <>
                <TextField
                  id="admin-name"
                  label="Name"
                  variant="standard"
                  value={adminName}
                  style={{ display: "block", marginBottom: 10 }}
                />
                <TextField
                  id="admin-date"
                  label="Date"
                  type="date"
                  variant="standard"
                  value={adminDate}
                  style={{ display: "block", marginBottom: 10 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitSignatures}
            disabled={submitting}
            style={{ minWidth: 200 }}
          >
            {submitting ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </div>
      </Container>
    </>
  );
}
