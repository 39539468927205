import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Stack,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
  Alert,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import GET_CAMPAIGNS_FOR_RESULTS from "../graphql/queries/getCampaignsForResults";
import SUBMIT_CAMPAIGN_RESULTS from "../graphql/mutations/submitCampaignResults";
import ImageGallery from "../sections/@dashboard/insights/ImageGallery";
import { CampaignResultsOcrUploader } from "../sections/@dashboard/campaigns/campaignResultsOcrUploader";
import GET_INFLUENCER_CAMPAIGN_RESULT from "../graphql/queries/getInfluencerCampaignResult";
import HowToVideoPopup from "../components/HowToVideoPopup";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "space-around",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "auto",
  display: "flex",
  justifyContent: "Start",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
  paddingBottom: 0,
}));

export default function CampaignResults() {
  const campaignMedias = ["Tiktok", "Instagram"];
  const initialState = {
    userId: localStorage.getItem("currentUser"),
    instaAccountsEngaged: 0,
    instaAccountsReached: 0,
    instaComments: 0,
    instaFollowers: 0,
    instaImpressions: 0,
    instaLikes: 0,
    instaLinkClicks: 0,
    instaNonFollowers: 0,
    instaProfileVisits: 0,
    instaReach: 0,
    instaPlays: 0,
    instaSaves: 0,
    instaShares: 0,
    instaStoryViews: 0,
    tiktokComments: 0,
    tiktokLikes: 0,
    tiktokViews: 0,
    tiktokFollowers: 0,
    tiktokPlays: 0,
    tiktokSaves: 0,
    tiktokShares: 0,
    tiktokWatchFullVideo: "0",
    tiktokAverageWatchTime: "0",
    link: [],
    discountCodeUsed: false,
  };
  const [notification, setNotification] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedCampaignMedia, setSelectedCampaignMedia] = useState("");
  const [selectedCampaignType, setSelectedCampaignType] = useState("");
  const [openScreenshotUploader, setOpenScreenshotUploader] = useState(false);
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [screenshot, setScreenshot] = useState([]);
  const [editFields, setEditFields] = useState(false);
  const [prevResultFields, setPrevResultFields] = useState(initialState);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [youtubeId, setYoutubeId] = useState("");

  const updateResultFields = (stats) => {
    console.log(stats);
    selectedCampaignMedia === "Instagram"
      ? setResultFields({
        ...resultFields,
        instaAccountsReached: stats["AccountsReached"] || 0,
        instaFollowers: stats["Followers"] || 0,
        instaNonFollowers: stats["NonFollowers"] || 0,
        instaSaves: stats["Saves"] || 0,
        instaShares: stats["Shares"] || 0,
        instaImpressions: stats["Impressions"] || 0,
        instaProfileVisits: stats["Plays"] || 0,
        instaLinkClicks: stats["LinkClicks"] || 0,
        instaAccountsEngaged: stats["AccountsEngaged"] || 0,
        instaLikes: stats["Likes"] || 0,
        instaComments: stats["Comments"] || 0,
        instaPlays: stats["Plays"] || 0,
      })
      : setResultFields({
        ...resultFields,
        tiktokComments: stats["Comments"] || 0,
        tiktokLikes: stats["Likes"] || 0,
        tiktokPlays: stats["Plays"] || 0,
        tiktokSaves: stats["Saved"] || 0,
        tiktokShares: stats["Shared"] || 0,
        tiktokWatchFullVideo: stats["WatchedFullVideoTime"] || "0",
        tiktokAverageWatchTime: stats["AverageWatchTime"] || "0",
      });
  };

  const [resultFields, setResultFields] = useState(initialState);

  const { data: myCampaigns } = useQuery(GET_CAMPAIGNS_FOR_RESULTS, {
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
  });

  const { data: campaignResult, refetch: refetchCampaignResult } = useQuery(
    GET_INFLUENCER_CAMPAIGN_RESULT,
    {
      skip: selectedCampaignType === "",
      variables: {
        userId: localStorage.getItem("currentUser"),
        campaignId: selectedCampaign.id || "0",
        campaignResultStatus: selectedCampaignType,
        campaignResultMedia: selectedCampaignMedia,
      },
      onCompleted: (res) => {
        res.getInfluencerCampaignResult &&
          setResultFields({
            ...resultFields,
            instaAccountsEngaged:
              res.getInfluencerCampaignResult.instaAccountsEngaged || 0,
            instaAccountsReached:
              res.getInfluencerCampaignResult.instaAccountsReached || 0,
            instaComments: res.getInfluencerCampaignResult.instaComments || 0,
            instaFollowers: res.getInfluencerCampaignResult.instaFollowers || 0,
            instaImpressions:
              res.getInfluencerCampaignResult.instaImpressions || 0,
            instaLikes: res.getInfluencerCampaignResult.instaLikes || 0,
            instaLinkClicks:
              res.getInfluencerCampaignResult.instaLinkClicks || 0,
            instaNonFollowers:
              res.getInfluencerCampaignResult.instaNonFollowers || 0,
            instaProfileVisits:
              res.getInfluencerCampaignResult.instaProfileVisits || 0,
            instaReach: res.getInfluencerCampaignResult.instaReach || 0,
            instaSaves: res.getInfluencerCampaignResult.instaSaves || 0,
            instaShares: res.getInfluencerCampaignResult.instaShares || 0,
            instaStoryViews:
              res.getInfluencerCampaignResult.instaStoryViews || 0,
            tiktokComments: res.getInfluencerCampaignResult.tiktokComments || 0,
            tiktokLikes: res.getInfluencerCampaignResult.tiktokLikes || 0,
            tiktokPlays: res.getInfluencerCampaignResult.tiktokPlays || 0,
            instaPlays: res.getInfluencerCampaignResult.instaPlays || 0,
            tiktokSaves: res.getInfluencerCampaignResult.tiktokSaves || 0,
            tiktokShares: res.getInfluencerCampaignResult.tiktokShares || 0,
            tiktokViews: res.getInfluencerCampaignResult.tiktokViews || 0,
            tiktokFollowers:
              res.getInfluencerCampaignResult.tiktokFollowers || 0,
            tiktokWatchFullVideo:
              res.getInfluencerCampaignResult.tiktokWatchFullVideo || "0",
            tiktokAverageWatchTime:
              res.getInfluencerCampaignResult.tiktokAverageWatchTime || "0",
            link:
              JSON.parse(res.getInfluencerCampaignResult.link)[0]?.split("|") ||
              [],
          });
      },
    }
  );

  const [submitResult] = useMutation(SUBMIT_CAMPAIGN_RESULTS, {
    onCompleted: () => {
      setNotification(true);
      setResultFields(initialState);
      refetchCampaignResult();
    },
    refetchQueries: ["getMyCampaignsForResults"],
  });

  const handleResultSubmission = () => {
    submitResult({
      variables: {
        userId: resultFields.userId,
        campaignId: selectedCampaign.id,
        campaignResultMedia: selectedCampaignMedia,
        campaignResultStatus: selectedCampaignType,
        instaAccountsEngaged: parseInt(resultFields.instaAccountsEngaged),
        instaAccountsReached: parseInt(resultFields.instaAccountsReached),
        instaComments: parseInt(resultFields.instaComments),
        instaFollowers: parseInt(resultFields.instaFollowers),
        instaImpressions: parseInt(resultFields.instaImpressions),
        instaLikes: parseInt(resultFields.instaLikes),
        instaLinkClicks: parseInt(resultFields.instaLinkClicks),
        instaNonFollowers: parseInt(resultFields.instaNonFollowers),
        instaProfileVisits: parseInt(resultFields.instaProfileVisits),
        instaReach: parseInt(resultFields.instaReach),
        instaSaves: parseInt(resultFields.instaSaves),
        instaShares: parseInt(resultFields.instaShares),
        instaStoryViews: parseInt(resultFields.instaStoryViews),
        instaPlays: parseInt(resultFields.instaPlays),
        tiktokComments: parseInt(resultFields.tiktokComments),
        tiktokLikes: parseInt(resultFields.tiktokLikes),
        tiktokShares: parseInt(resultFields.tiktokShares),
        tiktokViews: parseInt(resultFields.tiktokViews),
        tiktokFollowers: parseInt(resultFields.tiktokFollowers),
        tiktokPlays: parseInt(resultFields.tiktokPlays),
        tiktokSaves: parseInt(resultFields.tiktokSaves),
        tiktokWatchFullVideo: String(resultFields.tiktokWatchFullVideo),
        tiktokAverageWatchTime: String(resultFields.tiktokAverageWatchTime),
        link: screenshot.join("|").toString(),
        discountCodeUsed: Boolean(resultFields.discountCodeUsed),
      },
    });
  };

  const handleChange = (type, value) => {
    setEditFields(false);
    setResultFields(initialState);
    setScreenshot([]);
    setShowImageGallery(false);
    if (type === "campaign") {
      setSelectedCampaign(value);
      setSelectedCampaignMedia("");
      setSelectedCampaignType("");
    }
    if (type === "media") {
      setSelectedCampaignMedia(value);
      value === "Tiktok"
        ? setSelectedCampaignType("Reel")
        : setSelectedCampaignType("");
    }
    if (type === "type") {
      setSelectedCampaignType(value);
    }
  };



  useEffect(() => {
    if(selectedCampaignMedia=="Tiktok"){
      if(selectedCampaignType=="Reel"){
        setYoutubeId("AOliLvY_97M")
        setDescription("Watch the video below to learn how to upload your campaign results for reels")
        setTitle("How to upload your campaign results ")
      }

    }
    else if(selectedCampaignMedia=="Instagram"){
      if(selectedCampaignType=="Reel"){
        setYoutubeId("AXXNQi5r3cQ")
        setDescription("Watch the video below to learn how to upload your campaign results for reels")
        setTitle("How to upload your campaign results ")
      }
      else if(selectedCampaignType=="Post"){
        setYoutubeId("A2AMuqzF0M8")
        setDescription("Watch the video below to learn how to upload your campaign results for posts")
        setTitle("How to upload your campaign results ")
      }
      else if(selectedCampaignType=="Story"){
        setYoutubeId("P1Ie4aVJmVE")
        setDescription("Watch the video below to learn how to upload your campaign results for stories")
        setTitle("How to upload your campaign results ")
      }
    }
  }, [selectedCampaignMedia, selectedCampaignType]);

  return (
    <>
      <Helmet>
        <title> Insights | Authentic Influencers </title>
      </Helmet>

      <StyledRoot>
        <Snackbar open={notification} autoHideDuration={6000}>
          <Alert severity="success" sx={{ width: "100%" }}>
            Submission Successful
          </Alert>
        </Snackbar>
        <StyledSection>
          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                Enter Campaign Results
              </Typography>

              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel id="campaign-select-label">
                  Select Campaign
                </InputLabel>
                <Select
                  labelId="campaign-select-label"
                  id="campaign-select"
                  label="Select Campaign"
                  onChange={(e) => handleChange("campaign", e.target.value)}
                  value={selectedCampaign}
                >
                  {myCampaigns?.getMyCampaignsForResults?.map(
                    (campaign, index) => (
                      <MenuItem
                        value={campaign}
                        key={`${campaign.id}-${index}`}
                      >
                        {campaign.name}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>

              {selectedCampaign && (
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="campaign-media-select-label">
                    Select Campaign Media
                  </InputLabel>
                  <Select
                    labelId="campaign-media-select-label"
                    id="campaign-media-select"
                    label="Select Campaign Media"
                    onChange={(e) => handleChange("media", e.target.value)}
                    value={selectedCampaignMedia || ""}
                  >
                    {selectedCampaign.media === "All" ? (
                      campaignMedias.map((media, index) => (
                        <MenuItem value={media} key={index}>
                          {media}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        value={
                          selectedCampaign.media === "TikTok"
                            ? "Tiktok"
                            : selectedCampaign.media
                        }
                      >
                        {selectedCampaign.media === "TikTok"
                          ? "Tiktok"
                          : selectedCampaign.media}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}

              {selectedCampaignMedia === "Instagram" && (
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="campaign-type-select-label">
                    Select Campaign Type
                  </InputLabel>
                  <Select
                    labelId="campaign-type-select-label"
                    id="campaign-type-select"
                    label="Select Campaign Type"
                    onChange={(e) => handleChange("type", e.target.value)}
                    value={selectedCampaignType || ""}
                  >
                    {Object.keys(selectedCampaign)
                      .filter(
                        (key) =>
                          key.includes("includes") && selectedCampaign[key]
                      )
                      .map((key, index) => {
                        const type = key.replace("includes", "");
                        const value =
                          type === "Stories"
                            ? "Story"
                            : type === "Reels"
                              ? "Reel"
                              : type;

                        if (
                          value === "Videos" &&
                          selectedCampaign["includesPictures"]
                        )
                          return null;

                        if (value === "Videos" || value === "Pictures") {
                          return (
                            <MenuItem value="Post" key={index}>
                              Post
                            </MenuItem>
                          );
                        }

                        return (
                          <MenuItem value={value} key={index}>
                            {value}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}

              <Divider sx={{ my: 3 }} />

              {selectedCampaignMedia && selectedCampaignType && (<div style={{marginLeft: "40%", marginBottom: "20px"}}>
                <HowToVideoPopup
                  title={title}
                  description={description}
                  youtubeId={youtubeId}
                  />
              </div>
              )}
              {campaignResult?.getInfluencerCampaignResult && !editFields && (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setEditFields(true);
                    setPrevResultFields(resultFields);
                    setShowImageGallery(true);
                  }}
                >
                  Edit
                </Button>
              )}

              {editFields && (
                <>
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    setEditFields(false);
                    setResultFields(prevResultFields);
                  }}
                >
                  Cancel
                </Button>
                </>
              )}

              {selectedCampaignMedia === "Instagram" &&
                selectedCampaignType !== "" && (
                  <Stack spacing={3} marginY={3}>
                    {campaignResult?.getInfluencerCampaignResult &&
                      !editFields ? (
                      <>
                        <TextField
                          label="Accounts Reached"
                          value={resultFields.instaAccountsReached}
                          disabled
                        />
                        <TextField
                          label="Followers"
                          value={`${resultFields.instaFollowers}%`}
                          disabled
                        />
                        <TextField
                          label="Non Followers"
                          value={`${resultFields.instaNonFollowers}%`}
                          disabled
                        />
                        <TextField
                          label="Saves"
                          value={resultFields.instaSaves}
                          disabled
                        />
                        <TextField
                          label="Shares"
                          value={resultFields.instaShares}
                          disabled
                        />
                        {selectedCampaignType === "Post" && (
                          <TextField
                            label="Views"
                            value={resultFields.instaProfileVisits}
                            disabled
                          />
                        )}
                        {selectedCampaignType === "Story" && (
                          <>
                            <TextField
                              label="Link Clicks"
                              value={resultFields.instaLinkClicks}
                              disabled
                            />
                            <TextField
                              label="Views"
                              value={resultFields.instaPlays}
                              disabled
                            />
                          </>
                        )}
                        {selectedCampaignType !== "Reel" && (
                          <TextField
                            label="Impressions"
                            value={resultFields.instaImpressions}
                            disabled
                          />
                        )}
                        {selectedCampaignType === "Reel" && <TextField
                          label="Plays"
                          value={resultFields.instaPlays ?? "--"}
                          disabled
                        />}

                        {selectedCampaignType !== "Story" && (
                          <>
                            {selectedCampaignType !== "Reel" && <TextField
                              label="Accounts Engaged"
                              value={resultFields.instaAccountsEngaged}
                              disabled
                            />}
                            <TextField
                              label="Likes"
                              value={resultFields.instaLikes}
                              disabled
                            />
                            <TextField
                              label="Comments"
                              value={resultFields.instaComments}
                              disabled
                            />
                            <DialogContent>
                              <ImageGallery images={resultFields.link} />
                            </DialogContent>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <TextField
                          name="Accounts Reached"
                          label="Accounts Reached"
                          value={resultFields.instaAccountsReached}
                          onChange={(e) =>
                            setResultFields({
                              ...resultFields,
                              instaAccountsReached: e.target.value,
                            })
                          }
                        />
                        <TextField
                          name="Followers"
                          label="Followers"
                          value={resultFields.instaFollowers}
                          onChange={(e) =>
                            setResultFields({
                              ...resultFields,
                              instaFollowers: e.target.value,
                            })
                          }
                        />
                        <TextField
                          name="Non Followers"
                          label="Non Followers"
                          value={resultFields.instaNonFollowers}
                          onChange={(e) =>
                            setResultFields({
                              ...resultFields,
                              instaNonFollowers: e.target.value,
                            })
                          }
                        />
                        <TextField
                          name="Saves"
                          label="Saves"
                          value={resultFields.instaSaves}
                          onChange={(e) =>
                            setResultFields({
                              ...resultFields,
                              instaSaves: e.target.value,
                            })
                          }
                        />
                        <TextField
                          name="Shares"
                          label="Shares"
                          value={resultFields.instaShares}
                          onChange={(e) =>
                            setResultFields({
                              ...resultFields,
                              instaShares: e.target.value,
                            })
                          }
                        />

                        {selectedCampaignType === "Post" && (
                          <TextField
                            name="Views"
                            label="Views"
                            value={resultFields.instaProfileVisits}
                            onChange={(e) =>
                              setResultFields({
                                ...resultFields,
                                instaProfileVisits: e.target.value,
                              })
                            }
                          />

                        )}

                        {selectedCampaignType === "Story" && (
                          <>
                            <TextField
                              name="Link Clicks"
                              label="Link Clicks"
                              value={resultFields.instaLinkClicks}
                              onChange={(e) =>
                                setResultFields({
                                  ...resultFields,
                                  instaLinkClicks: e.target.value,
                                })
                              }
                            />
                            <TextField
                            name="Views"
                            label="Views"
                            value={resultFields.instaPlays}
                            onChange={(e) =>
                              setResultFields({
                                ...resultFields,
                                instaPlays: e.target.value,
                              })
                            }
                            />
                          </>
                        )}

                        {selectedCampaignType === "Reel" ? (
                          <TextField
                            name="Plays"
                            label="Plays"
                            value={resultFields.instaPlays}
                            onChange={(e) =>
                              setResultFields({
                                ...resultFields,
                                instaPlays: e.target.value,
                              })
                            }
                          />
                        ) : (
                          <TextField
                            name="Impressions"
                            label="Impressions"
                            value={resultFields.instaImpressions}
                            onChange={(e) =>
                              setResultFields({
                                ...resultFields,
                                instaImpressions: e.target.value,
                              })
                            }
                          />
                        )}

                        {selectedCampaignType !== "Story" && (
                          <>
                            {selectedCampaignType !== "Reel" && <TextField
                              name="Accounts Engaged"
                              label="Accounts Engaged"
                              value={resultFields.instaAccountsEngaged}
                              onChange={(e) =>
                                setResultFields({
                                  ...resultFields,
                                  instaAccountsEngaged: e.target.value,
                                })
                              }
                            />}
                            <TextField
                              name="Likes"
                              label="Likes"
                              value={resultFields.instaLikes}
                              onChange={(e) =>
                                setResultFields({
                                  ...resultFields,
                                  instaLikes: e.target.value,
                                })
                              }
                            />
                            <TextField
                              name="Comments"
                              label="Comments"
                              value={resultFields.instaComments}
                              onChange={(e) =>
                                setResultFields({
                                  ...resultFields,
                                  instaComments: e.target.value,
                                })
                              }
                            />
                          </>
                        )}
                      </>
                    )}
                  </Stack>
                )}

              {selectedCampaignMedia === "Tiktok" && (
                <Stack spacing={3} marginY={3}>
                  {campaignResult?.getInfluencerCampaignResult &&
                    !editFields ? (
                    <>
                      <TextField
                        label="Plays"
                        value={resultFields.tiktokPlays}
                        disabled
                      />
                      <TextField
                        label="Comments"
                        value={resultFields.tiktokComments}
                        disabled
                      />
                      <TextField
                        label="Likes"
                        value={resultFields.tiktokLikes}
                        disabled
                      />
                      <TextField
                        label="Shares"
                        value={resultFields.tiktokShares}
                        disabled
                      />
                      <TextField
                        label="Saves"
                        value={resultFields.tiktokSaves}
                        disabled
                      />
                      <TextField
                        label="Watch Full Video Time"
                        value={resultFields.tiktokWatchFullVideo}
                        disabled
                      />
                      <TextField
                        label="Average Watch Time"
                        value={resultFields.tiktokAverageWatchTime}
                        disabled
                      />
                      <DialogContent>
                        <ImageGallery images={resultFields.link} />
                      </DialogContent>
                    </>
                  ) : (
                    <>
                      <TextField
                        name="Plays"
                        label="Plays"
                        type="number"
                        value={resultFields.tiktokPlays}
                        onChange={(e) =>
                          setResultFields({
                            ...resultFields,
                            tiktokPlays: e.target.value,
                          })
                        }
                      />
                      <TextField
                        name="Comments"
                        label="Comments"
                        type="number"
                        value={resultFields.tiktokComments}
                        onChange={(e) =>
                          setResultFields({
                            ...resultFields,
                            tiktokComments: e.target.value,
                          })
                        }
                      />
                      <TextField
                        name="Likes"
                        label="Likes"
                        value={resultFields.tiktokLikes}
                        onChange={(e) =>
                          setResultFields({
                            ...resultFields,
                            tiktokLikes: e.target.value,
                          })
                        }
                      />
                      <TextField
                        name="Shares"
                        label="Shares"
                        value={resultFields.tiktokShares}
                        onChange={(e) =>
                          setResultFields({
                            ...resultFields,
                            tiktokShares: e.target.value,
                          })
                        }
                      />
                      <TextField
                        name="Saves"
                        label="Saves"
                        value={resultFields.tiktokSaves}
                        onChange={(e) =>
                          setResultFields({
                            ...resultFields,
                            tiktokSaves: e.target.value,
                          })
                        }
                      />
                      <TextField
                        name="Watch Full Video Time"
                        label="Watch Full Video Time"
                        type="text"
                        value={resultFields.tiktokWatchFullVideo}
                        onChange={(e) =>
                          setResultFields({
                            ...resultFields,
                            tiktokWatchFullVideo: e.target.value,
                          })
                        }
                      />
                      <TextField
                        name="Average Watch Time"
                        label="Average Watch Time"
                        type="text"
                        value={resultFields.tiktokAverageWatchTime}
                        onChange={(e) =>
                          setResultFields({
                            ...resultFields,
                            tiktokAverageWatchTime: e.target.value,
                          })
                        }
                      />
                    </>
                  )}
                </Stack>
              )}

              {selectedCampaignType !== "" &&
                (campaignResult?.getInfluencerCampaignResult === null ||
                  editFields) && (
                  <>
                    <FormControlLabel
                      label="Discount Codes Used"
                      control={
                        <Checkbox
                          checked={resultFields.discountCodeUsed}
                          onChange={(e) => {
                            console.log("screenshot urls\n\n", screenshot);
                            setResultFields({
                              ...resultFields,
                              discountCodeUsed: !resultFields.discountCodeUsed,
                            });
                          }}
                          sx={{
                            color: "#FFA3C7",
                            "&.Mui-checked": {
                              color: "#EF2E83",
                            },
                          }}
                        />
                      }
                    />

                    <Button
                      variant="contained"
                      sx={{ marginTop: 2 }}
                      onClick={() => setOpenScreenshotUploader(true)}
                    >
                      Profile Screenshot
                    </Button>
                    {showImageGallery && (
                      <DialogContent>
                        <ImageGallery images={screenshot} />
                      </DialogContent>
                    )}

                    <CampaignResultsOcrUploader
                      open={openScreenshotUploader}
                      setOpen={setOpenScreenshotUploader}
                      setTiktokPictureUrls={setScreenshot}
                      setImageGallery={setShowImageGallery}
                      campaignMedia={selectedCampaignMedia}
                      campaignType={selectedCampaignType}
                      setFields={updateResultFields}
                    />

                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      value={screenshot}
                      onClick={() => handleResultSubmission()}
                      sx={{
                        marginTop: 3,
                      }}
                      style={{
                        marginBottom: 40,
                      }}
                      disabled={screenshot.length === [].length}
                    >
                      Submit
                    </LoadingButton>
                  </>
                )}
            </StyledContent>
          </Container>
        </StyledSection>
      </StyledRoot>
    </>
  );
}
